<template>
    <v-container fluid>
        <v-list-item three-line>
            <v-list-item-content>
                <QuickTranslation
                  translation-key="pre-records.label"
                  tag="h1"
                />
            </v-list-item-content>
        </v-list-item>
        <pre-records-table></pre-records-table>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import PreRecordsTable from "./RecordsTablePreComponent";

    export default {
        name: 'pre-records-tab',
        components: {
            PreRecordsTable
        },
        data: () => ({
            // User Profile
            profile: null
        }),
        computed: {
            ...mapGetters({
                storageDeviceTypesActiveByCompany: 'storage_type/storageDeviceTypesActiveByCompany',
            }),
            ...mapState({
                tabIndexState: (state) => state.records.tabIndex
            }),

            tabIndex: {
                get() {
                    return this.tabIndexState;
                },
                set(data) {
                    this.$store.commit('records/SET_TAB_INDEX', {data});
                }
            },
        },

        created() {
        },

        methods: {
            ...mapActions({
                fetchStorageTypesActiveByCompany: 'storage_type/fetchStorageTypesActiveByCompany'
            }),
        },
    }
</script>

<style scoped></style>
