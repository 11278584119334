<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="recordsByCompany"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
      :server-items-length="totalFromServer"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
                onkeypress="return (event.charCode != 34)"
                v-on:keyup.86="replace"
                v-on:keyup.ctrl.86="replace"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                :clearable="true"
                @keypress.enter="searchForText"
                @click:clear="searchAll"
                @click:append="searchForText"
                append-icon="mdi-magnify"
                :label="$t('common.titles.filter')"
                single-line
                hide-details
                :disabled="loading"
            />
          </v-col>
          <v-col lg="4" md="4" sm="8" xl="4">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-badge
                bordered
                color="neuter"
                overlap
                :content="cantFilters"
                left
                :value="cantFilters"
              >
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="gray"
                  style="border-radius: 1.5em 0 0 1.5em;"
                  @click="openFilters"
                  class="mr-1"
                >
                  <v-icon small color="neuter" left>mdi-filter-variant</v-icon>
                  <span class="neuter--text">{{
                    $t("common.buttons.filter")
                  }}</span>
                </v-btn>
              </v-badge>
              <!--ADD APPOINTMENT *********************************************************************-->
              <template>
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="gray"
                  style="border-radius: 0 1.5em 1.5em 0;"
                  @click="addRecords"
                  :loading="loadingProcess"
                  v-if="check([{ domain: 'Record', permisions: ['Write'] }])"
                  :disabled="loadingProcess"
                >
                  <v-icon small color="neuter" left>mdi-plus</v-icon>
                  <span class="neuter--text">{{
                    $t("common.buttons.new")
                  }}</span>
                  <template v-slot:loader>
                    <v-progress-circular indeterminate size="12" width="2" />
                  </template>
                </v-btn>
              </template>
              <!--END ADD APPOINTMENT *****************************************************************-->
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  small
                  close
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  @click:close="cleanFilter(chip.value)"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template v-slot:item.record_date_time="{ item }">
        <span>{{ getDateFormat(item.record_date_time) }}</span>
      </template>
      <template v-slot:item.mobile="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              class="mr-4"
              color="primary"
              v-if="item.isActiveOnMobile"
              @click="showForMobil(item)"
            >
              mdi-cellphone-text
            </v-icon>
          </template>
          <span>
            {{
              $t("common.buttons.press_for") +
                " " +
                $t("common.buttons.desactivate")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              class="mr-4"
              color="grey lighten-1"
              v-if="!item.isActiveOnMobile"
              @click="showForMobil(item)"
            >
              mdi-cellphone-off
            </v-icon>
          </template>
          <span>
            {{
              $t("common.buttons.press_for") +
                " " +
                $t("common.buttons.activate")
            }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.sdi_origin_name="{ item }">
        <v-chip v-if="item.sdi_origin" class="ma-1" small>{{
          item.sdi_origin.primaryLocation.name +
            " | " +
            translate(item.sdi_origin.control_label)
        }}</v-chip>
        <v-chip v-else class="ma-1" small disabled>{{
          $t("pre-records.titles.labels.notAvailable")
        }}</v-chip>
      </template>
      <template v-slot:item.sdi_destination_name="{ item }">
        <v-chip v-if="item.sdi_destination" class="ma-1" small>{{
          item.sdi_destination.primaryLocation.name +
            " | " +
            translate(item.sdi_destination.control_label)
        }}</v-chip>
        <v-chip v-else class="ma-1" small disabled>{{
          $t("pre-records.titles.labels.notAvailable")
        }}</v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="getDetails(item)"
              v-if="check([{ domain: 'Record', permisions: ['Read'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="barcode(item)"
            >
              mdi-qrcode
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.qrcode") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              :disabled="loadingProcess"
              @click="editItem(item)"
              v-if="
                checkAdmin() ||
                  (profile.id === item.person_in_charge.id &&
                    check([{ domain: 'Record', permisions: ['Update'] }]))
              "
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              v-if="
                checkAdmin() ||
                  (profile.id === item.person_in_charge.id &&
                    check([{ domain: 'Record', permisions: ['Delete'] }]))
              "
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("pre-records.label") }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!--        Dialog Delete-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title  class="headline secondary t-bw-secondary--text" >{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--        Dialog MORE DETAILS -->
    <v-row justify="center">
      <v-dialog persistent v-model="dialogDetails" max-width="800px" scrollable>
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between px-14">
            <v-toolbar fixed flat>
              <span
                class="headline"
                style="font-family: 'Avenir', Helvetica, Arial, sans-serif !important; font-weight: bold !important; color:rgba(35,35,35,0.96) !important;"
                >{{ $t("common.titles.details") }}</span
              >
              <span v-if="recordSelected && recordSelected.length > 0">
                {{ recordSelected.person_in_charge.name }}
              </span>
            </v-toolbar>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-6">
            <v-container>
              <v-row v-if="dialogDetails">
                <div class="d-flex flex-wrap">
                  <v-card elevation="0" width="100%" class="my-2">
                      <v-card-title class="pb-1 pt-1 primary darken-5 t-bw-primary--text font-weight-medium">
                          <span style="font-size: 16px">{{$t('pre-records.titles.preRegistration')}}</span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;">
                          <v-card elevation="0" width="100%" class="mb-3">
                              <v-card-text style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;">
                                  <div class="d-flex flex-wrap mb-2 pa-3 background__body">
                                      <div style="width: 200px; padding:5px;" v-for="(values,i) in headers" :key="i">
                                          <p v-if="!(values.value == 'action' || values.value == 'mobile')"  class="black--text mb-0 font-weight-bold"
                                          >
                                              {{values ? values.text : ''}}:
                                          </p>
                                          <p v-if="!(values.value == 'action' || values.value == 'mobile')" class="black--text mb-2">
                                              {{ recordsCompanyById[values.value] }}
                                          </p>
                                      </div>
                                  </div>
                              </v-card-text>
                          </v-card>
                      </v-card-text>
                      <v-divider />
                  </v-card>

                  <div
                    style="width: 250px"
                    v-if="existStructures"
                    v-for="item in getStructures"
                    :key="item.id"
                  >
                    <div
                      class="ma-2 pa-2"
                    >
                      <p
                        class="black--text mb-0 font-weight-bold"
                      >
                        {{ translate(item.structure.language_key, true) }}:
                      </p>
                      <p
                        class="black--text mb-0"
                        v-if="
                          typeof item.recordStructureValue.record_value ===
                            'object'
                        "
                        v-for="structureName in item.recordStructureValue
                          .record_value"
                      >
                        {{ getValue(item.structure, structureName) }}
                      </p>
                      <p
                        class="black--text"
                        v-if="
                          !item.recordStructureValue.record_value ||
                            typeof item.recordStructureValue.record_value !==
                              'object'
                        "
                      >
                        {{
                          getValue(
                            item.structure,
                            item.recordStructureValue.record_value
                          )
                        }}
                      </p>
                    </div>
                  </div>

                  <v-card
                    elevation="0" width="100%" class="my-2"
                    v-for="(group, index) in getGroups"
                    :key="index"
                    v-if="existGroups"
                  >
                    <v-card-title
                      class="pb-1 pt-1 primary darken-5 t-bw-primary--text font-weight-medium"
                    >
                      <span style=" font-size: 16px">{{
                        translate(group.languageKey, true).toUpperCase()
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;"
                    >
                      <v-card
                        elevation="0" width="100%" class="mb-3"
                        v-for="row in group.rows"
                        v-bind:key="row.groupRow"
                      >
                        <v-card-text
                          style="padding-left: 0 !important; padding-right: 0 !important; padding-top: 0 !important;"
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3 background__body"
                          >
                            <div
                              style="width: 200px"
                              v-for="values in row.values"
                            >
                              <p
                                class="black--text mb-0 font-weight-bold"
                              >
                                {{
                                  values
                                    ? translate(
                                        values.structure.language_key,
                                        true
                                      )
                                    : ""
                                }}:
                              </p>
                              <p
                                class="black--text mb-2"
                                v-for="(item, index) in values
                                  .recordStructureValue.record_value"
                                :key="index"
                              >
                                {{ getValue(values.structure, item) }}
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-6">
            <v-toolbar flat fixed>
              <v-icon
                class="pl-3"
                v-if="
                  recordSelected.evidences &&
                    recordSelected.evidences.length > 0
                "
                color="primary"
                @click="getEvidences"
              >
                mdi-download-multiple
              </v-icon>
              <v-spacer />
              <v-btn class="mx-4 t-bw-primary--text" small elevation="0" color="primary"@click="closeDetails">
                {{ $t("common.buttons.close") }}
              </v-btn>
            </v-toolbar>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import PermisionService from "../../services/PermisionService";
import "jspdf-autotable";
import _ from "lodash";
import LanguajeService from "../../services/LanguajeService";
import axios from "axios";
import { generalMixin } from '../../mixins/general.mixin'

const FILTER_VALUES = [
  "company_id",
  "min_punctuation",
  "max_punctuation",
  "surveyStateType",
  "template_names",
  "pageableDTO",
  " start_record_date_time",
  "end_record_date_time",
  "document_authority_ids",
  "document_type_ids",
  "document_description_ids",
  "document_date",
  "expiration_date",
  "storage_devices_ids",
  "structure",
  "record_ids",
  "products_ids",
  "seasons_ids",
  "sellers_names",
  "contracts_names",
  "volumeB_range",
  "volumeN_range",
  "quebra_range",
  "contracts_dates",
  "sellers_date",
  "withExternalVisibility",
  "isActiveOnMobile"
];

export default {
  name: "pre-records-table",
  mixins: [generalMixin],
  data: () => ({
    // Profile
    profile: null,
    // Filters
    search: '',
    show: false,
    // Load
    loading: false,
    loadingProcess: true,
    // Pagination
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
    options: {},
    // Record Data Model
    editedIndex: -1,
    // Dialog Delete
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    // Dialog Details
    dialogDetails: false,
  }),
  watch: {
    options: {
      async handler() {
        this.optionsTable = this.options;
        this.loading = true;
        await this.searchText()
      },
      deep: true,
    },
  },
  async created() {
    this.type = "pre-records";
    this.$store.commit('ui/SET_FILTERS_SORT', []);

    this.profile = JSON.parse(localStorage.getItem("profile"));

    await this.fetchListAuthorityProcess([this.profile.id, this.$toast]).finally(() => {
      this.loadingProcess = false;
    })
    // this.fetchListLocations([this.profile.company_id, this.$toast]);
    // this.fetchListStorage([this.profile, this.$toast]);
  },
  mounted() {
    this.$store.commit("ui/FILTERS_ORIGINAL_STATE");
    let filtersPreRecords = JSON.parse(
      localStorage.getItem("filtersPreRecords")
    );

    if (filtersPreRecords) {
      this.locations.origin_locations_filters = filtersPreRecords.origin_locations_filters
        ? filtersPreRecords.origin_locations_filters
        : null;
      this.locations.destination_locations_filters = filtersPreRecords.destination_locations_filters
        ? filtersPreRecords.destination_locations_filters
        : null;
      this.filtersObjects = filtersPreRecords.filtersObjects
        ? filtersPreRecords.filtersObjects
        : [];

      this.filters.storage_device_types_ids =
        filtersPreRecords.filtersObjects.storage_device_types_ids &&
        filtersPreRecords.filtersObjects.storage_device_types_ids.length > 0
          ? this.getByProperty(
              filtersPreRecords.filtersObjects.storage_device_types_ids,
              "storage_device_type_id"
            )
          : null;
      this.filters.sdi_destination_ids =
        filtersPreRecords.filtersObjects.sdi_destination_ids &&
        filtersPreRecords.filtersObjects.sdi_destination_ids.length > 0
          ? filtersPreRecords.filtersObjects.sdi_destination_ids
          : null;
      this.filters.sdi_origin_ids =
        filtersPreRecords.filtersObjects.sdi_origin_ids &&
        filtersPreRecords.filtersObjects.sdi_origin_ids.length > 0
          ? filtersPreRecords.filtersObjects.sdi_origin_ids
          : null;
      this.filtersChips =
        filtersPreRecords.filtersChips &&
        filtersPreRecords.filtersChips.length > 0
          ? filtersPreRecords.filtersChips
          : [];
      this.cantFilters =
        filtersPreRecords.cantFilters &&
        filtersPreRecords.cantFilters.length > 0
          ? filtersPreRecords.cantFilters
          : null;
    }

    if (this.filtersRecordDate) this.dateRangeText();

    if (
      this.locations.origin_locations_filters ||
      this.locations.destination_locations_filters ||
      this.filtersObjects
    ) {
      // this.closeFilterDialog();
    }

    this.$root.$on("setFiltersPreRecords", () => {
      this.closeFilterDialog();
    });

    this.$root.$on("getResultsPaginatedByCompany", () => {
      this.getRecordsPaginatedByCompany();
    });
  },
  computed: {
    recordSelected: {
      get() {
        return this.$store.getters["records/record"];
      },
      set(val) {
        this.$store.commit("records/SET_RECORD", val);
      },
    },
    type: {
      get() {
        return this.$store.getters["ui/GET_FILTER_TYPE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTER_TYPE", val);
      },
    },
    drawer: {
      get() {
        return this.$store.getters["ui/GET_DRAWER"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DRAWER", val);
      },
    },
    filters: {
      get() {
        return this.$store.getters["ui/GET_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTERS", val);
      },
    },
    structureFilter: {
      get() {
        return this.$store.getters["ui/GET_STRUCTURE_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_STRUCTURE_FILTERS", val);
      },
    },
    optionsTable: {
      get() {
        return this.$store.getters["ui/GET_OPTIONS_TABLE"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OPTIONS_TABLE", val);
      },
    },
    locations: {
      get() {
        return this.$store.getters["ui/GET_LOCATIONS_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_LOCATIONS_FILTERS", val);
      },
    },
    filterRecord: {
      get() {
        return this.$store.getters["ui/GET_STAGE_ID"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_STAGE_ID", val);
      },
    },
    filtersObjects: {
      get() {
        return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val);
      },
    },
    date: {
      get() {
        return this.$store.getters["ui/GET_DATE_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_DATE_FILTERS", val);
      },
    },
    filtersChips: {
      get() {
        return this.$store.getters["ui/GET_FILTERS_CHIPS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_FILTERS_CHIPS", val);
      },
    },
    cantFilters: {
      get() {
        return this.$store.getters["ui/GET_COUNT_FILTERS"];
      },
      set(val) {
        this.$store.dispatch("ui/SET_COUNT_FILTERS", val);
      },
    },
    preRecordSelected: {
      get() {
        return this.$store.getters["prerecords/preRecord"];
      },
      set(val) {
        this.$store.commit("prerecords/SET_PRE_RECORD", val);
      },
    },
    source: {
      get() {
        return this.$store.getters['prerecords/source']
      },
      set(val) {
        this.$store.commit('prerecords/SET_SOURCE', val)
      },
    },
    request: {
      get() {
        return this.$store.getters['prerecords/request']
      },
      set(val) {
        this.$store.commit('prerecords/SET_REQUEST', val)
      },
    },
    ...mapGetters({
      storageDeviceTypesActiveByCompany:
        "storage_type/storageDeviceTypesActiveByCompany",
      recordsByCompany: "prerecords/recordsByCompany",
      recordsCompanyById: "prerecords/recordsCompanyById",
      allProccess: "proccess/proccess",
      pageEnum: "reports/pageEnum",
      recordsToExports: "prerecords/recordsToExports",
      totalFromServer: "prerecords/recordsByCompanyTotalCount",
      structuresDetails: "prerecords/structures",
      storages: "authority/authoritiesStoragesDevices",
      authorities: "authority/authorities",
      locationsList: "authority/authoritiesLocations",
      //----ui-----------------------------------------
      filterHeader: "ui/GET_HEADERS_PRE_RECORDS",
    }),

    // Computed filter origin storage by location
    getOriginStorageByLocationsFiltered() {
      return this.getStorageByLocationsFilteredAction(
        this.locations.origin_locations_filters
      );
    },
    // Computed filter destination storage by location
    getDestiStorageByLocationsFiltered() {
      return this.getStorageByLocationsFilteredAction(
        this.locations.destination_locations_filters
      );
    },
    // Table headers
    headers: () => [
      {
        text: i18n.t("pre-records.fields.name"),
        value: "prerecords_name",
        val: "prerecords",
        type: "text",
        sortable: false,
        width: 150,
      },
      {
        text: i18n.t("pre-records.fields.stage"),
        value: "sdt_to_show_translate",
        val: "sdt_to_show_translate",
        sortable: false,
        type: "text",
        width: 100,
      },
      {
        text: i18n.t("pre-records.fields.process"),
        value: "process_name",
        val: "process",
        type: "text",
        width: 130,
      },
      {
        text: i18n.t("pre-records.fields.origin"),
        locale: i18n.t("pre-records.fields.originReg"),
        value: "sdi_origin_name",
        val: "sdi_origin",
        type: "multi",
      },
      {
        text: i18n.t("pre-records.fields.destination"),
        locale: i18n.t("pre-records.fields.destinationRed"),
        value: "sdi_destination_name",
        val: "sdi_destination",
        type: "multi",
      },
      {
        text: i18n.t("pre-records.fields.mobile"),
        value: "mobile",
        sortable: false,
        width: 120,
        align: "center",
      },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        sortable: false,
        type: "actions",
        width: 150,
        align: "center",
      },
    ],
    // Exist structures
    existStructures() {
      return (
        this.preRecordSelected.recordStructureValuesOrganized.structures &&
        this.preRecordSelected.recordStructureValuesOrganized.structures
          .length > 0
      );
    },
    // Exist groups
    existGroups() {
      return (
        this.preRecordSelected.recordStructureValuesOrganized.groups &&
        this.preRecordSelected.recordStructureValuesOrganized.groups.length > 0
      );
    },
    // Get structures
    getStructures() {
      return this.preRecordSelected.recordStructureValuesOrganized.structures
        ? this.preRecordSelected.recordStructureValuesOrganized.structures
        : [];
    },
    // Get groups
    getGroups() {
      if (this.flagGroups) this.flagGroups = false;
      return this.preRecordSelected.recordStructureValuesOrganized.groups
        ? this.preRecordSelected.recordStructureValuesOrganized.groups
        : [];
    },
  },

  methods: {
    ...mapActions({
      fetchListRecordsByCompanyPaginated:
        "prerecords/fetchListAppointmentsByCompanyPaginated",
      findPreRecordById: "prerecords/findPreRecordById",
      deleteRecord: "prerecords/deleteRecord",
      // fetchListLocations: "locations/fetchListLocations",
      // fetchListStorage: "storage/fetchListAllStorageByCompany",
      changeMobileActive: "prerecords/changeMobileActive",
      fetchListAuthorityProcess: 'authority/fetchListAuthorityProcess',
    }),
    async searchAll() {
      this.options.page = 1
      await this.getRecordsPaginatedByCompany()
    },
    async searchForText() {
      this.options.page = 1
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
      this.$store.commit('ui/CLEAR_LOCAL_STORAGE')
      await this.searchText()
    },
    async searchText() {
      this.request = true
      const CancelToken = axios.CancelToken
      this.source = CancelToken.source()

      this.loading = true
      this.$emit('isDisable', this.loading)
      const keys = [
        "company_id",
        "pageableDTO",
        "process_ids",
        "sdi_origin_ids",
        "sdi_destination_ids",
        "storage_device_types_ids",
        'isActiveOnMobile'
      ];
      let filters = _.pick(this.filters, keys)

      filters.company_id = this.profile.company_id
      filters.stringFilter = this.search

      const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'desc'
              : 'asc'
      const sort =
          this.options.sortBy.length === 0
              ? 'id'
              : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '') {
        await this.fetchListRecordsByCompanyPaginated([filters, this.source, this.$toast]).finally(
          () => {
            this.loading = false;
          }
        );
      } else {
        await this.closeFilterDialog()
      }

    },
    async openFilters() {
      this.type = "pre-records";

      const filtersPreRecords = JSON.parse(localStorage.getItem('filtersPreRecords'))

      await this.$root.$emit("set:location:origin", {
        locations: filtersPreRecords.origin_locations_filters,
        storageDevice: filtersPreRecords.filtersObjects.sdi_origin_ids
      })

      await this.$root.$emit("set:location:destination", {
        locations: filtersPreRecords.destination_locations_filters,
        storageDevice: filtersPreRecords.filtersObjects.sdi_destination_ids
      })

      this.drawer = true;
    },
    showForMobil(item) {
      this.changeMobileActive([
        this.profile.company_id,
        item.id,
        !item.isActiveOnMobile,
        this.$toast,
      ]).finally(() => {
        if (this.search && this.search.length > 0) {
          this.searchForText()
        } else {
          this.search = ''
          this.getRecordsPaginatedByCompany();
        }
      });
    },
    translate(key) {
      return LanguajeService.getKey3(key);
    },
    // Fetch Records
    async getRecordsPaginatedByCompany() {
      this.loading = true;

      this.request = true
      const CancelToken = axios.CancelToken
      this.source = CancelToken.source()

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? "desc"
          : "asc";
      const sort =
        this.options.sortBy.length === 0
          ? "id"
          : _.filter(this.headers, { value: this.options.sortBy[0] })[0].val;
      this.filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      };
      this.filters.company_id = this.profile.company_id;

      const keys = [
        "company_id",
        "pageableDTO",
        "process_ids",
        "sdi_origin_ids",
        "sdi_destination_ids",
        "storage_device_types_ids",
        'isActiveOnMobile'
      ];
      const filters = _.pick(this.filters, keys);

      await this.fetchListRecordsByCompanyPaginated([filters, this.source, this.$toast]).finally(
        () => {
          this.loading = false;
        }
      );
    },
    // Search all records by "search" criteria
    searchOnAll(items) {
      return items.filter((item) => {
        return Object.values(item)
          .join(",")
          .toUpperCase()
          .includes(this.search.toUpperCase());
      });
    },
    // Get date format
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format(this.dateFormat);
    },
    // Get Record Structure Values Save Model
    getRecordStructureValueModel() {
      return _.cloneDeep({
        groups: {
          key: "groups",
          values: [],
        },
        structures: {
          key: "structures",
          values: [],
        },
      });
    },
    // Check permission
    check(permissions) {
      return PermisionService.check(permissions);
    },
    // Check permission Admin
    checkAdmin() {
      return PermisionService.checkAdmin();
    },
    // Add Record
    addRecords() {
      localStorage.setItem("prerecords_editOrSave", -1);
      this.$store.commit("prerecords/SET_ORIGINAL_STATE_PRE_RECORD");
      this.$router.push("/prerecordsAdd");
    },
    // Edit Record
    async editItem(item) {
      this.loading = true;
      await this.findPreRecordById([item.id, this.$toast]).finally(() => {
        this.loading = false;
      });
      this.editedIndex = item.id;
      localStorage.setItem("prerecords_editOrSave", this.editedIndex);
      await this.$router.push({ path: "/prerecordsAdd" });
    },
    // Show Delete dialog for delete Record
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },
    // Close Delete dialog for delete Record
    dialogClose() {
      this.showDialog = false;
    },
    // Dialog data
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    // Delete Action
    deleteItem() {
      this.dialogClose();
      this.deleteRecord([this.deletingItem.id, this.$toast]).finally(() => {
        this.getRecordsPaginatedByCompany();
      });
    },
    // Get data more details dialog
    async getDetails(item) {
      this.loading = true;
      await this.findPreRecordById([item.id, this.$toast]).finally(() => {
        this.loading = false;
      });
      this.dialogDetails = true;
    },
    // Close Details (Structures) dialog
    closeDetails() {
      this.dialogDetails = false;
    },
    // Labels action
    barcode(item) {
      let barcodeData = Object.assign({}, item);
      barcodeData.id = item.id;
      barcodeData.languageKey = item.languageKey;
      // localStorage.setItem('records_barcode', JSON.stringify(barcodeData));
      this.$store.commit("general/PRE_RECORDS_BARCODE", barcodeData);
      this.$router.push({ path: "/printRecordLabel/pre_recordsLabel" });
    },
    checkFileExtension(extenxion) {
      const extension = ["jpg", "jpeg", "png", "bmp"];
      return extension.includes(extenxion);
    },

    // FILTERS
    cleanFilter(tag) {
      let filtersPreRecords = JSON.parse(
        localStorage.getItem("filtersPreRecords")
      );

      if (tag === 'isActiveOnMobile') {
        filtersPreRecords.filtersObjects.isActiveOnMobile = null
        this.filtersObjects.isActiveOnMobile = null
        this.filters.isActiveOnMobile = null
      } else if (this.filters[tag]) {
        if (this.filtersObjects[tag]) {
          this.filtersObjects[tag] = [];
          filtersPreRecords.filtersObjects[tag] = [];
        }
        this.filters[tag] = [];
        if (tag === "sdi_origin_ids") {
          this.locations.origin_locations_filters = [];
          filtersPreRecords.origin_locations_filters = [];

          this.$root.$emit("set:location:origin", {
            locations: [],
            storageDevice: []
          })
        } else if (tag === "sdi_destination_ids") {
          this.locations.destination_locations_filters = [];
          filtersPreRecords.destination_locations_filters = [];

          this.$root.$emit("set:location:destination", {
            locations: [],
            storageDevice: []
          })
        }
        if (
          tag === "end_record_date_time" ||
          tag === "start_record_date_time"
        ) {
          this.date.filtersRecordDate = [];
          filtersPreRecords.filtersRecordDate = [];
          this.date.filtersDate = [];
          filtersPreRecords.filtersDate = [];
          this.filters["end_record_date_time"] = null;
          this.filters["start_record_date_time"] = null;
          this.filtersObjects.start_record_date_time = null;
          filtersPreRecords.start_record_date_time = null;
        }
      }
      localStorage.setItem(
        "filtersPreRecords",
        JSON.stringify(filtersPreRecords)
      );
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
    },
    getCantFilters() {
      let cant = 0;
      _.forEach(this.filters, (value, key) => {
        if (
          ![...FILTER_VALUES, "storage_device_types_ids"].includes(key) &&
          value !== null
        ) {
          cant +=
            key === "end_record_date_time" || key === "start_record_date_time"
              ? 1
              : value.length;
        }
        cant +=
          key === "storage_device_types_ids" && value && value.length > 0
            ? 1
            : 0;
      });
      this.cantFilters = cant;
    },
    filteredChips() {
      let chips = [];
      _.forEach(this.filters, (value, key) => {
        if (key === 'isActiveOnMobile') {
          if (value != null) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }
        } else if (
          !FILTER_VALUES.includes(key) &&
          this.filters[key] &&
          this.filters[key].length > 0
        ) {
          chips.push({
            value: key,
            name: _.filter(this.filterHeader, { val: key })[0].text,
          });
        }
      });
      this.filtersChips = chips;
    },
    selectAllStructure(structure_id, structure_values) {
      _.forEach(this.structuresDetails, (structure) => {
        if (structure.structure_id === structure_id) {
          this.structureFilter[structure.structure_id].filters.splice(
            0,
            this.structureFilter[structure.structure_id].filters
          );
          // structure.filters.splice(0, structure.filters.length);
          _.forEach(structure_values, (value) => {
            this.structureFilter[structure.structure_id].filters.push(value);
          });
        }
      });
    },
    applyFilters() {
      let filtersPreRecords = JSON.parse(
        localStorage.getItem("filtersPreRecords")
      );

      if (filtersPreRecords) {
        this.filters.process_ids =
          filtersPreRecords.filtersObjects.process_ids &&
          filtersPreRecords.filtersObjects.process_ids.length > 0
            ? this.getByProperty(
                filtersPreRecords.filtersObjects.process_ids,
                "id"
              )
            : null;
        this.filters.isActiveOnMobile =
            filtersPreRecords.filtersObjects.isActiveOnMobile
        this.filters.sdi_destination_ids =
          filtersPreRecords.filtersObjects.sdi_destination_ids &&
          filtersPreRecords.filtersObjects.sdi_destination_ids.length > 0
            ? filtersPreRecords.filtersObjects.sdi_destination_ids
            : null;
        this.filters.sdi_origin_ids =
          filtersPreRecords.filtersObjects.sdi_origin_ids &&
          filtersPreRecords.filtersObjects.sdi_origin_ids.length > 0
            ? filtersPreRecords.filtersObjects.sdi_origin_ids
            : null;
        this.filters.storage_device_types_ids =
          filtersPreRecords.filtersObjects.storage_device_types_ids &&
          filtersPreRecords.filtersObjects.storage_device_types_ids.length > 0
            ? this.getByProperty(
                filtersPreRecords.filtersObjects.storage_device_types_ids,
                "storage_device_type_id"
              )
            : null;
      }
      this.getRecordsPaginatedByCompany();
    },
    closeFilterDialog() {
      this.search = '';
      this.applyFilters();
      this.filteredChips();
      this.getCantFilters();
      this.drawer = false;
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
    getStorageByLocationsFilteredAction(data) {
      return data && data.length > 0
        ? _.filter(this.storages, (storage) => {
            if (data.includes(storage.primaryLocationId)) {
              return storage;
            }
          })
        : [];
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','');
      this.search =  event.currentTarget.value.toString().replaceAll('"','');
    }
  },
  destroyed() {
    this.request = false
    if (this.source) {
      this.source.cancel('cancelRequest')
      this.source = null
    }

    this.$root.$off("setFiltersPreRecords");
    this.$root.$off("getResultsPaginatedByCompany");
  },
};
</script>

<style lang="scss" scoped>
  .background__body {
    background-color: color-mix(in srgb, var(--v-primary-base) 8%, transparent);
  }
.v-data-table {
  margin: 25px;
}
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
